import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";

interface SectionProps extends VariantProps<typeof sectionVariants> {
  children: React.ReactNode;
}

const sectionVariants = cva("relative md:py-24 py-16", {
  variants: {
    variant: {
      default: "bg-white",
      secondary: "bg-gray-50",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Section: React.FC<SectionProps> = (props) => {
  const { children, variant } = props;
  return (
    <section className={cn(sectionVariants({ variant }))}>
      <div className="container relative mx-auto">{children}</div>
    </section>
  );
};

export default Section;
