import { combineReducers, createStore } from "redux";
import { repairDataReducer } from "./reducers";

const rootReducer = combineReducers({
  repairData: repairDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer);

export default store;
