interface SidebarLayananProps {
  children: React.ReactNode;
  title: string;
}

const SidebarLayanan: React.FC<SidebarLayananProps> = (props) => {
  const { children, title } = props;
  return (
    <div className="sticky top-40">
      <h5 className="text-lg font-semibold bg-gray-50 shadow rounded-md p-2 text-center mt-8">
        {title}
      </h5>
      <div className="flex flex-col gap-3 mt-8">{children}</div>
    </div>
  );
};

export default SidebarLayanan;
