import { SET_REPAIR_DATA, SET_LAYANAN_DATA } from "./actions";

const initialState = {
  repairData: [],
  layananData: [],
};

export const repairDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_REPAIR_DATA:
      return {
        ...state,
        repairData: action.payload,
      };
    case SET_LAYANAN_DATA:
      return {
        ...state,
        layananData: action.payload,
      };
    default:
      return state;
  }
};
