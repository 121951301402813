export const SET_REPAIR_DATA = "SET_REPAIR_DATA";
export const SET_LAYANAN_DATA = "SET_LAYANAN_DATA";

export const setRepairData = (data: any) => ({
  type: SET_REPAIR_DATA,
  payload: data,
});

export const setLayananData = (data: any) => ({
  type: SET_LAYANAN_DATA,
  payload: data,
});
