import { FaMoneyBillWave, FaShuttleVan } from "react-icons/fa";
import { AiFillSecurityScan } from "react-icons/ai";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GiChoice } from "react-icons/gi";
import { SlDiamond } from "react-icons/sl";
import { FaMagnifyingGlass } from "react-icons/fa6";
import {
  BsFileEarmarkCheck,
  BsHandThumbsUp,
  BsTools,
  BsRocketTakeoff,
  BsCalendar3,
} from "react-icons/bs";

export const Benefits = [
  {
    title: "GRATIS Biaya Pengecekan",
    content:
      "Anda akan menerima laporan dari hasil diagnosa teknisi dan tidak akan dikenakan cas pengecekan unit untuk kategori dan kerusakan apapun",
    icon: (
      <FaMoneyBillWave className="h-10 w-10 stroke-1 group-hover:stroke-[1.5px] group-hover:text-orange-600 transition-all duration-500 ease-in-out mx-auto" />
    ),
  },
  {
    title: "GRATIS Konsultasi 24/7",
    content:
      "Anda dapat berkonsultasi langsung dengan customer support kami secara GRATIS dan solutif.",
    icon: (
      <TfiHeadphoneAlt className="h-10 w-10 stroke-1 group-hover:stroke-[1.5px] group-hover:text-orange-600 transition-all duration-500 ease-in-out mx-auto" />
    ),
  },
  {
    title: "GRATIS Pick Up dan Delivery",
    content:
      "Kami menawarkan program pick up dan delivery secara GRATIS dengan kurir kami yang terlatih dan sesuai SOP.",
    icon: (
      <FaShuttleVan className="h-10 w-10 stroke-1 group-hover:stroke-[1.5px] group-hover:text-orange-600 transition-all duration-500 ease-in-out mx-auto" />
    ),
  },
  {
    title: "BEBAS pilih program",
    content:
      "Kami menyediakan berbagai pilihan program layanan yang bisa disesuaikan dengan fleksibilitas waktu Anda, seperti Kunjungan service, antar-jemput unit, visit store.",
    icon: (
      <GiChoice className="h-10 w-10 stroke-1 group-hover:stroke-[1.5px] group-hover:text-orange-600 transition-all duration-500 ease-in-out mx-auto" />
    ),
  },
  {
    title: "KEAMANAN data terjamin",
    content:
      "Anda tidak perlu khawatir data diunit Anda bocor atau diakses oleh tim kami. Karena kami telah menerapkan SOP mengenai hak privasi customer.",
    icon: (
      <AiFillSecurityScan className="h-10 w-10 stroke-1 group-hover:stroke-[1.5px] group-hover:text-orange-600 transition-all duration-500 ease-in-out mx-auto" />
    ),
  },
  {
    title: "KUALITAS part terjamin dan diagnosa akurat",
    content:
      "Kami selalu menjaga kualitas part dan memperhatikan kompatibilitasnya dengan unit Anda. ",
    icon: (
      <SlDiamond className="h-10 w-10 stroke-1 group-hover:stroke-[1.5px] group-hover:text-orange-600 transition-all duration-500 ease-in-out mx-auto" />
    ),
  },
];

export const AdsBenefits = [
  {
    icon: (
      <FaMagnifyingGlass className={`text-white  relative text-4xl z-10`} />
    ),
    title: "Transparan",
    deskripsi:
      "Anda Bisa Langsung Bertanya Pada Teknisi Secara Real Time di Store Kami dan Pengecekan Secara Sistem",
  },
  {
    icon: (
      <BsFileEarmarkCheck className={`text-white  relative text-4xl z-10`} />
    ),
    title: "Bebas Biaya Pengecekan",
    deskripsi: "Tim Kami Secara Gratis Melakukan Pengecekan Kepada Unit Anda",
  },
  {
    icon: <BsHandThumbsUp className={`text-white  relative text-4xl z-10`} />,
    title: "Part Berkualitas",
    deskripsi:
      "Kami Menggunakan Komponen Yang Berkualitas Untuk Memperbaiki Perangkat Anda!",
  },
  {
    icon: <BsTools className={`text-white  relative text-4xl z-10`} />,
    title: "Tools Lengkap",
    deskripsi:
      "Peralatan yang Kami Gunakan Berkualitas dan Telah Sesuai Standar yang Telah Ditentukan",
  },
  {
    icon: <BsRocketTakeoff className={`text-white  relative text-4xl z-10`} />,
    title: "Pengerjaan Cepat",
    deskripsi:
      "Proses Pengerjaan Bisa Kami Selesaikan Dalam Hitungan Hari Bahkan Dalam Hitungan Jam",
  },
  {
    icon: <BsCalendar3 className={`text-white  relative text-4xl z-10`} />,
    title: "Garansi Panjang",
    deskripsi: "Dapatkan After Sales Dengan Garansi 90 Hari",
  },
];
